<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          <p>{{ $t("addwarehouse") }}</p>
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("company") }}</label
            >
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.companyId"
              disabled
              required
            >
              <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option
                v-for="company in companies"
                :key="company"
                :value="company.id"
              >
                {{ company.company_code }} - {{ company.company_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("branch") }}</label
            >
            <select class="form-select" v-model="form.branchId" required>
              <option
                v-for="branch in branches"
                :key="branch"
                :value="branch.id"
              >
                {{ branch.code }} {{ branch.short_name }} {{ branch.name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("warehousecode") }}</label
            >
            <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
            <input
              v-model="form.warehouse_code"
              :maxlength="form.companyId == '1' ? 3 : ''"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center mb-4"> -->
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("initials") }}</label
            >
            <input
              v-model="form.warehouse_short_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("warehousename") }}</label
            >
            <input
              v-model="form.warehouse_full_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center mb-4"> -->
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("warehousetype") }}</label
            >
            <select v-model="form.warehouse_type" class="form-select" required>
              <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option value="คลังหลัก">จอมธนา</option>
              <option value="คลังรอง">agent</option>
              <option value="คลังรอง">อื่นๆ</option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("telephone") }}</label
            >
            <input
              v-model="form.tel"
              type="text"
              class="form-control"
              id="validationTooltip01"
              v-mask-number
              maxlength="10"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("fax")
            }}</label>
            <input
              v-model="form.fax"
              type="text"
              class="form-control"
              id="validationTooltip01"
              v-mask-number
              maxlength="10"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("address") }}</label
            >
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>

          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("province") }}</label
            >
            <v-select
              v-model="form.province"
              :options="provinces"
              :reduce="(item) => item.id"
              label="name_in_thai"
            >
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.name_in_thai }}
                </div>
              </template>

              <template v-slot:option="option">
                {{ option.name_in_thai }}
              </template>
            </v-select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("district") }}</label
            >
            <v-select
              v-model="form.district"
              :options="districts"
              :reduce="(item) => item.id"
              label="name_in_thai"
            >
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.name_in_thai }}
                </div>
              </template>

              <template v-slot:option="option">
                {{ option.name_in_thai }}
              </template>
            </v-select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("subdistrict") }}</label
            >
            <v-select
              v-model="form.sub_district"
              :options="subdistricts"
              :reduce="(item) => item.id"
              label="name_in_thai"
            >
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.name_in_thai }}
                </div>
              </template>

              <template v-slot:option="option">
                {{ option.name_in_thai }}
              </template>
            </v-select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("postcode") }}</label
            >
            <input
              v-model="form.zipcode"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              v-mask-number
              maxlength="5"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <!-- <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("latitude")
            }}</label>
            <input
              v-model="form.lat"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div> -->
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("longitude")
            }}</label>
            <input
              v-model="form.lng"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="form-label col-sm-10"
            ></label>

            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->

        <div
          class="row d-flex justify-content-center mb-4"
          v-if="form.companyId == !'1'"
        >
          <div class="col-sm-10 mt-6 mb-6">
            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5"
            >
              <el-checkbox
                v-model="form.is_sale_warehouse"
                type="checkbox"
                :value="true"
              />
              <span class="form-check-label"> {{ $t("warehouses") }} </span>
            </label>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >กำหนดเป็นคลังหลัก</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.selectMainWarehouse"
            >
              <option value="1">ใช่</option>
              <option value="2">ไม่ใช่</option>
            </select>
          </div>
        </div> -->
      </div>
      <div class="card-header">
        <h3 class="" style="font-size: 20px; font-weight: bold">
          {{ $t("contact") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center mb-4 mt-4">
          <label for="validationTooltip01" class="form-label col-sm-2">{{
            $t("name")
          }}</label>
          <label for="validationTooltip01" class="form-label col-sm-2">{{
            $t("position")
          }}</label>
          <label for="validationTooltip01" class="form-label col-sm-2">{{
            $t("telephone")
          }}</label>
          <label for="validationTooltip01" class="form-label col-sm-4">{{
            $t("email")
          }}</label>
        </div>
        <div
          class="row d-flex justify-content-center mb-6"
          v-for="(contact, index) in contacts"
          :key="contact"
        >
          <div class="col-sm-2">
            <input
              v-model="contact.name"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.position"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.tel"
              type="text"
              class="form-control"
              id="validationTooltip01"
              v-mask-number
              maxlength="10"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.email"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-light btn-danger me-2"
              style="font-size: 14px; font-weight: bold"
              @click="removeContact(index)"
            >
              -
            </button>
            <button
              type="button"
              class="btn btn-light btn-primary"
              style="font-size: 14px; font-weight: bold"
              @click="addContact(index)"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import companyApi from "@/api/user/";
import addressApi from "@/api/address/";

export default {
  setup() {
    document.title = "J&N | เพิ่มคลังสินค้า";
  },
  data() {
    return {
      loading: false,
      isSubmit: false,
      // parseInt(localStorage.getItem("companyId")),
      form: {
        // companyId: parseInt(localStorage.getItem("companyId")),
        // code: "",
        // short_name: "",
        // name: "",
        // selectMainWarehouse: "1",
        // status: "0",
      },
      companies: [],
      branches: [],
      provinces: [],
      districts: [],
      subdistricts: [],

      contacts: [{ name: "", position: "", tel: "", email: "" }],
      ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    };
  },
  watch: {
    "form.companyId"(val) {
      this.getAllBranch(val);
    },
    "form.province"(val) {
      console.log("formformform", val);
      this.getAllDistrict(val);
    },
    "form.district"(val) {
      console.log("formsubdistrict", val);
      this.getAllSubdistrict(val);
    },
  },
  created() {
    this.form.companyId = parseInt(localStorage.getItem("companyId"));
    this.getAllCompany();
    this.getAllProvince();
  },
  methods: {
    async getAllProvince() {
      // const response = await axios.get(`https://thai-drug-service-temp.yuzudigital.com/locations/findAllProvinces`);
      const response = await addressApi.province.getAllProvince();
      console.log("provinces", response.data);
      this.provinces = response.data;
    },
    async getAllDistrict(id) {
      const response = await addressApi.province.getAllDistrict(id);
      console.log("district", response.data);
      this.districts = response.data;
    },
    async getAllSubdistrict(id) {
      const response = await addressApi.province.getAllSubdistrict(id);
      console.log("subdistrict", response.data);
      this.subdistricts = response.data;
    },
    async getAllCompany(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await companyApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companies = responseData.data;

        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllBranch() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(this.form.companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    addContact(index) {
      console.log("index", index);
      this.contacts.splice(index + 1, 0, {
        name: "",
        position: "",
        tel: "",
        email: "",
      });
    },
    removeContact(index) {
      this.contacts.splice(index, 1);
    },
    async submitContacts(warehouseId) {
      let createResponse = [];

      // let data = {};
      // this.contacts.forEach(async (element) => {
      //   data = { ...element, warehouseId: warehouseId };
      //   createResponse = await whApi.contact.create(data);
      // });
      createResponse = await whApi.contact.create(this.contacts);
      // const data = {
      //   ...this.contacts,
      //   warehouseId: warehouseId,
      // };
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let createResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;

          if (this.isValid) {
            this.loading = true;

            this.form = {
              ...this.form,
              contacts: this.contacts,
              // warehouse_main: this.form.selectMainWarehouse === "1" ? true : false,
              companyId: parseInt(localStorage.getItem("companyId")),
            };

            createResponse = await whApi.warehouse.create(this.form);
            if (createResponse.response_status === "SUCCESS") {
              // await this.submitContacts(createResponse.data.id);
              Swal.fire({
                icon: "success",
                title: `${this.$t("addsuccess")} `,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/warehouse/WH1",
                  query: {},
                });
              });
              this.loading = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${createResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            }
          }
        }
      });
    },

    checkFormIsEmpty() {
      !this.form.warehouse_code
        ? (this.isValid = false)
        : !this.form.warehouse_short_name
        ? (this.isValid = false)
        : !this.form.warehouse_full_name
        ? (this.isValid = false)
        : !this.form.branchId
        ? (this.isValid = false)
        : !this.form.warehouse_type
        ? (this.isValid = false)
        : // : !this.form.lat
        // ? (this.isValid = false)
        // : !this.form.lng
        // ? (this.isValid = false)
        !this.form.tel
        ? (this.isValid = false)
        : // : !this.form.fax
          // ? (this.isValid = false)
          // : !this.form.selectMainWarehouse
          // ? (this.isValid = false)
          (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    cancel() {
      this.$router.push({
        path: "/warehouse/WH1",
        query: {},
      });
    },
  },
};
</script>



